import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { getActions, getStatusTag } from "../helpers/ordersHelper";
import { useEffect, useState } from "react";
import EsseLib from '../lib/esse';
import ESModal from "../components/esse-components/ESModal";
import { ModalViewOrder } from "../modals/ModalViewOrder";
import { ModalManageOrder } from "../modals/ModalManageOrder";
const { Title } = Typography;


function Orders() {

  const [loadData, setLoadData] = useState(true)

  const [state, setState] = useState({
    reloadDataReq: false,
    modalViewOrderOpen: false,
    modalManageOrderOpen: false,
    body: [],
    currentViewData: {},
    currentManageData: {},
    filters: {
      statuses: [10, 20, 30]
    }
})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(loadData)
    {
      setLoadData(false);let params = {
        code: "",
        title: "",
        body: "",
        statuses: state.filters.statuses
      }
      EsseLib.GetRequest("/orders", params).then((body) => {
          setState({...state, reloadDataReq: false, body: body})
      });
    }
  }, [loadData]);

  useEffect(() => {
    if(state.reloadDataReq){
      setLoadData(true);
    }
  }, [state])
  
const columns = [
  { title: 'Codice', dataIndex: 'code', key: 'code'},
  { title: 'Titolo Progetto', dataIndex: 'title', key: 'title'},
  EsseLib.IsAdmin() ?
    { title: 'Utente', dataIndex: 'userInfo', key: 'userInfo', render: (userInfo) => {
      return userInfo === undefined ? "N.D." : (userInfo?.firstName + " " + userInfo?.lastName)
    }} : {}, 
  { title: 'Stato', dataIndex: 'status', key: 'status', render: (status) => {
      return getStatusTag(status)
  }},
  { title: 'Azioni', dataIndex: 'actions', key: 'actions', render: (a, record) => {
      return getActions(record, [onViewClick, onManageClick])
  }},
]

let getOrder = (code) => {
  return new Promise((resolve, reject) => {
    let params = {
      code: code
    }
    EsseLib.GetRequest('/orders/', params).then(body => {
        if(body.length > 0)
        {
            resolve(body);
        }
    }).catch((err) => reject(err));
  });
}


let onViewClick = (code) => {
  getOrder(code).then((body) => {
    //console.log(body)
    setState({...state, modalViewOrderOpen: true, currentViewData: body[0]});
  });
}

let onManageClick = (code) => {
  getOrder(code).then((body) => {
    setState({...state, modalManageOrderOpen: true, currentManageData: body[0]});
  });
}

  let onModalManageOrderOk = (code, status, notes) => {
    updateOrder(code, status, notes).then(()=> {
      setState({...state, modalManageOrderOpen: false, currentManageData: undefined, reloadDataReq: true});
    });
  }

  let updateOrder = (code, status, notes) => {
    return new Promise((resolve, reject) => {
      if(code !== undefined && code !== "" && status !== undefined)
      {
        let params = {
          status: status
        };

        if(notes !== undefined) { params.notes = notes }

        EsseLib.PutRequest('/orders/'+code, params).then(body => {
          resolve();
        }).catch((err) => {reject(err)});
      }
    })
  }

  const onChange = (e) => {
    let newFiltersStatuses = state.filters.statuses;
    switch(e.target.value)
    {
      case "0":
        newFiltersStatuses = [10,20,30]
        break;
      case "1":
        newFiltersStatuses = [10,20,21,30,40]
        break;
    }
    setState({...state, filters: {...state.filters, statuses: newFiltersStatuses}, reloadDataReq: true});
  } 
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Ordini"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="0">
                    <Radio.Button value="0">Aperti</Radio.Button>
                    <Radio.Button value="1">Tutti</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.body}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <ESModal
            title="Stato Ordine"
            onCancel={() => setState({...state, modalViewOrderOpen: false})}
            open={state.modalViewOrderOpen}>
                <ModalViewOrder data={state.currentViewData}/>
            
        </ESModal>

        <ESModal
            title="Gestisci Ordine"
            noButtons={true}
            onOk={(e) => onModalManageOrderOk(e)}
            onCancel={() => setState({...state, modalManageOrderOpen: false})}
            open={state.modalManageOrderOpen}>
                <ModalManageOrder handleOk={onModalManageOrderOk}  data={state.currentManageData}/>
            
        </ESModal>
    </>
  );
}

export default Orders;
