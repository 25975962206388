import {createIntl, createIntlCache} from 'react-intl'
import fetch from '../auth/FetchInterceptor'
import { TIMEOUT } from '../auth/FetchInterceptor'
import { message } from 'antd';
import { AUTH_TOKEN, ROLE_ID } from '../configs/AppConfig';
import queryString from 'query-string';
import moment from 'moment'

export const ERROR_DURATION = 3
export const SUCCESS_DURATION = 4
const key = 'updatable';

const cache = createIntlCache()

class EsseLib {

	static currentAppLocale;
	static intl;
	static menuInstance;

	static history;

	static setCurrentAppLocale(locale)
	{
		EsseLib.currentAppLocale = locale;
		EsseLib.intl = createIntl({
			locale: EsseLib.currentAppLocale.locale,
			messages: EsseLib.currentAppLocale.messages,
		}, cache)
	}

    static GetLanguage(key)
	{
		return (EsseLib.intl.formatMessage({id: key}));
	}

	static GetDefaultNavPath(){
		return EsseLib.IsAdmin() ? "/dashboard" : "/quotations";
	}

	static IsLogged(){
		let authToken = localStorage.getItem(AUTH_TOKEN)
		return !EsseLib.IsNullOrWhiteSpace(authToken);
	}

	static IsAdmin(){
		let roleId = localStorage.getItem(ROLE_ID)
		return roleId === "ADMIN"
	}

	static IsNullOrWhiteSpace(val){
		return val === null ||
			   val ===  undefined ||
			   val ===  "";
	}

	static GetRequest(url, params) {
		return new Promise(function(resolve, reject)
		{
			let queryParams = queryString.stringify(params)
			fetch(url + "?" + queryParams, {method: 'GET', timeout: TIMEOUT})
			.then(body => {
			   if(body !== undefined)
			   {
				  resolve(body.data);
			   }
			})
			.catch((err) => {
				reject(err);
			})
		})
	}

	static PostRequest(url, data, silent) {
		return new Promise(function(resolve, reject)
		{
			silent = (silent === true) ? true : false

			if(!silent)
			{
				message.loading({ content: 'Aggiornamento dati...', key, duration: TIMEOUT/1000 });
			}
			fetch(url, {method: 'POST', data, timeout: TIMEOUT})
			.then((data) => {
				if(!silent)
				{
					EsseLib.HandleResponse(data.data);
				}
				resolve(data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static PutRequest(url, data, overrideHandle = false) {
		return new Promise(function(resolve, reject)
		{
			message.loading({ content: 'Aggiornamento dati...', key, duration: overrideHandle ? 1 : TIMEOUT/1000 });
			fetch(url, {method: 'PUT', data, timeout: TIMEOUT})
			.then((data) => {
				if(!overrideHandle)
				{
					EsseLib.HandleResponse(data.data);
				}
				resolve(data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static DeleteRequest(url, data, overrideHandle = false) {
		return new Promise(function(resolve, reject)
		{
			message.loading({ content: 'Aggiornamento dati...', key, duration: overrideHandle ? 1 : TIMEOUT/1000 });
			fetch(url, {method: 'DELETE', data, timeout: TIMEOUT})
			.then((data) => {
				if(!overrideHandle)
				{
					EsseLib.HandleResponse(data.data);
				}
				resolve(data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static DownloadFileRequest(url, params) {
		return new Promise(function(resolve, reject)
		{
			fetch(url, {method: 'GET', timeout: TIMEOUT, params: params, responseType: "blob"})
			.then(body => 
			{
				var blob = body.data;
				var filename = "";
				var disposition = body.headers['content-disposition'];
				if (disposition && disposition.indexOf('attachment') !== -1) {
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
				}

				if (typeof window.navigator.msSaveBlob !== 'undefined') {
					// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
					window.navigator.msSaveBlob(blob, filename);
				} else {
					var URL = window.URL || window.webkitURL;
					var downloadUrl = URL.createObjectURL(blob);

					if (filename) {
						// use HTML5 a[download] attribute to specify filename
						var a = document.createElement("a");
						// safari doesn't support this yet
						if (typeof a.download === 'undefined') {
							window.location.href = downloadUrl;
						} else {
							a.href = downloadUrl;
							a.download = filename;
							document.body.appendChild(a);
							a.click();
						}
					} else {
						window.location.href = downloadUrl;
					}

					setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
				}
			});
        });
	}

	static GetDateTimeIfValid(dt)
	{
		if(dt !== null)
		{
			let date = moment(dt);
			let isValid = date.isValid() && !(date.date() === 1 && date.month() === 0 && date.year() === 1970)
			return isValid ? date.format("DD/MM/yyyy HH:mm:ss") : ""
		}
		else
		{
			return ''
		}
	}


	static HandleResponse(data)
	{
		let resp = {
			status: 0, //0: unknown, 1: success, 2: error, 3: warning
			message: "errGeneric"
		}

		if(data?.status !== undefined && data?.message !== undefined)
		{
			resp.status = data.status;
			resp.message = data.message;
		}

		let html = EsseLib.GetLanguage(`backendMsg.${resp.message}`);

		switch(resp.status)
		{
			default:
				message.info({ content: "UNKNOWN RESPONSE", key, duration: ERROR_DURATION })
				break;
			case 1:
				message.success({ content: html, key, duration: ERROR_DURATION });
				break;
			case 2:
				message.error({ content: html, key, duration: ERROR_DURATION })
				break;
			case 3:
				message.warning({ content: html, key, duration: SUCCESS_DURATION });
				break;
		}
	}

	static GetLanguage(key)
	{
		return (EsseLib.intl.formatMessage({id: key}));
	}

	static GetRole()
	{
		return localStorage.getItem(ROLE_ID);
	}
}

export default EsseLib;