import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just" style={{textAlign: "center"}}>
        <Col xs={24} md={24} lg={24}>
          <div className="copyright">
            © 2024
            <a href="#a" className="font-weight-bold" target="_blank">
              Staples
            </a>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
