const dev = {
  API_ENDPOINT_URL: 'http://45.76.129.188:50443/',
  WSS_ENDPOINT_URL: 'wss://45.76.129.188:50443/ws',

  //API_ENDPOINT_URL: 'https://localhost:44340/',
  //WSS_ENDPOINT_URL: 'wss://wms.samac.it:50443/ws'
};

const prod = {
  API_ENDPOINT_URL: 'http://45.76.129.188:50443/',
  WSS_ENDPOINT_URL: 'wss://45.76.129.188:50443/ws',
};

const test = {
API_ENDPOINT_URL: 'https://api.test.com',
WSS_ENDPOINT_URL: 'wss://api.test.com/ws'
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
      case 'development':
          return dev
      case 'production':
          return prod
      case 'test':
          return test
      default:
          break;
  }
}

export const env = getEnv()
