
import axios from 'axios'
import { API_BASE_URL, AUTH_TOKEN } from './../configs/AppConfig'
import { notification, message } from 'antd';
import EsseLib from '../lib/esse'
export const TIMEOUT = 100000;

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: TIMEOUT
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
export const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {

	const jwtToken = localStorage.getItem(AUTH_TOKEN)

  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	config.headers['content-type'] = "application/json"
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
	  alert(config.headers[PUBLIC_REQUEST_KEY])
	  	// eslint-disable-next-line no-restricted-globals
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response
}, (error) => {
	
	let notificationParam = {
		message: 'Generic Error'
	}
	
	if (error?.response?.status === 400) {
		if(error.response.data?.title !== undefined && String(error.response.data.title).toUpperCase().indexOf("VALIDATION") >= 0)
		{
			notificationParam.message = "Errore Dati:";
			notificationParam.description = []
			for (const [key, value] of Object.entries(error.response.data.errors)) {
				notificationParam.description.push(<div>{key}: {"TODO"/*EsseLib.GetLanguage(value)*/}</div>);
			}
		}
	}

	// Remove token and redirect 
	if (error?.response?.status === 401 || error?.response?.status === 403) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN);
		/*localStorage.removeItem(FIRST_NAME);
		localStorage.removeItem(LAST_NAME);
		localStorage.removeItem(LN_ID);
		localStorage.removeItem(DEPT_ID);
		localStorage.removeItem(DEPT_DESCRIPTION);
		localStorage.removeItem(AVATAR);
		localStorage.removeItem(ENDORSABLE);

		localStorage.removeItem(ROLE);
		localStorage.removeItem(NAV_ROLE_SECTIONS);
		localStorage.removeItem(PATH_ROLE_SECTIONS);*/


		// eslint-disable-next-line no-restricted-globals
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error?.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error?.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error?.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	if(notification.message === "Generic Error")
	{
		notification.error(notificationParam)
	}
	else
	{
		message.error({ content: "Generic Error", key: "err", duration: 5 });
	}

	return Promise.reject(error);
});

export default service