//import antdZhCn from 'antd/es/locale/it_IT';
import it_IT from "antd/lib/locale/it_IT";

import itMsg from "../locales/it_IT.json";

const ItLang = {
  antd: it_IT,
  locale: 'it',
  messages: {
    ...itMsg
  },
};
export default ItLang;
