import { Badge, Steps, Form, Input, Row, Table, Tag, Upload, Select } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { PlusCircleOutlined, UploadOutlined, CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined } from "@ant-design/icons";
import EsseLib from '../lib/esse';
import { getTimeline, treatmentsOptions } from '../helpers/ordersHelper';

export const ModalViewOrder = (props) => {

  let fileDownload = () => {
    EsseLib.DownloadFileRequest("/quotations/files/3", {}).then((data) => {
            
        this.downloadFile(data.file, "myfile.pdf");

    });
}
    return (<>
        {getTimeline(props.data.status)}
        <span><b>Nome Progetto: </b>{props.data.title}</span><br/>
        <span><b>Descrizione Progetto: </b>{props.data.body}</span><br/>
        <span><b>Codice Preventivo: </b>{props.data.quotation_Code}</span>
        <br/><br/>
        <span><b>Prezzo: </b>{props.data.price}€</span>
        <br/><br/>
        {!EsseLib.IsNullOrWhiteSpace(props.data.treatments) && props.data.treatments.indexOf(',') > 0 && <>
            <b>Trattamenti richiesti:</b>
            <Select
                mode="multiple"
                disabled
                style={{ width: '100%' }}
                value={props.data.treatments.split(',')}
                placeholder="Selezionare tipo di lavorazione"
                options={treatmentsOptions}
            />
        </>}
        <br/><br/>
        <span><b>Files Allegati al Preventivo: </b></span>
        <br/>
        <ul>
        {props.data.files !== undefined && props.data.files.map(f => {
            return  <li><a target='#t' onClick={fileDownload}>{f.name}</a></li>
        })}
        </ul>
        </>
    );
  };