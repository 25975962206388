import { Select, Button, Form, Input, Checkbox, Table, Tag, Upload } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { PlusCircleOutlined, UploadOutlined, CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined } from "@ant-design/icons";
import React from 'react';
import { treatmentsOptions } from '../helpers/ordersHelper';

export const ModalNewQuotation = (props) => {
    
    const [state, setState] = React.useState({
        hasTreatment: false
    })

    const uploadProps = {
        onRemove: (file) => {
          const index = props.fileList.indexOf(file);
          const newFileList = props.fileList.slice();
          newFileList.splice(index, 1);
          props.setFileList(newFileList);
        },
        beforeUpload: (file) => {
            props.setFileList([...props.fileList, file]);
          return false;
        },
        fileList: props.fileList,
      };

      const handleChange = (value) => {
        //console.log(`selected ${value}`);
      };

      

      let onCheckChange = (e) => {
        setState({...state, hasTreatment: e.target.checked });
      }

    return (<>
        <Form
            form={props.form}
            >
            <Form.Item
                name="txtProjectName" 
                label="Nome Progetto"
                rules={[
                    {
                        required: true,
                        message: "Obbligatorio",
                    },
                ]}>
                <Input></Input>
            </Form.Item>
            <Form.Item
                name="txtProjectDescription" 
                label="Descrizione Progetto"
                rules={[
                    {
                        required: true,
                        message: "Obbligatorio",
                    },
                ]}>
                <TextArea></TextArea>
            </Form.Item>
            <Checkbox value={state.hasTreatment} onChange={onCheckChange}>Richiedi anche offerta con trattamento</Checkbox>
            <span style={{display: state.hasTreatment ? "block" : "none"}}><br/>
                <Form.Item
                    name="cmbProjectTreatments" 
                    label="Tipologia di trattamento">
                   
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Selezionare tipo di lavorazione"
                        onChange={handleChange}
                        options={treatmentsOptions}
                    />
                </Form.Item>
            </span>
            {!state.hasTreatment && <><br/><br/></> }
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Carica Disegni</Button>
            </Upload>
        </Form></>
    );
  };