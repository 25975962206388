import { Button, Tag, Tooltip, Steps, Popconfirm } from "antd";
import { CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined, ToolOutlined, FileProtectOutlined, SignatureOutlined, InfoOutlined, InfoCircleOutlined } from "@ant-design/icons";
import EsseLib from "../lib/esse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignature } from '@fortawesome/free-solid-svg-icons'

const { Step } = Steps;

export function getStatusTag (status) {
    let rv = <></>
    switch(status)
    {
        case 10:
            rv = <Tag color="blue">Disegni inviati</Tag>
            break;
        case 20:
            rv = <Tag color="gold">In Lavorazione</Tag>
            break;
        case 21:
            rv = <Tag color="volcano">Preventivo Annullato</Tag>
            break;
        case 22:
            rv = <Tag color="volcano">Preventivo Declinato</Tag>
            break;
        case 30:
            rv = <Tag color="lime">Offerta Disponibile</Tag>
            break;
        case 31:
            rv = <Tag color="volcano">Offerta Rifiutata</Tag>
            break;
        case 40:
            rv = <Tag color="green">Ordinato</Tag>
            break;
        
    }
    return rv;
}

export function getActions (record, functions) {

    let show =  <Tooltip title="Vedi">
                    <Button type="primary" onClick={() => {functions[0](record.code)}} icon={<EyeOutlined />} size="small"></Button>&nbsp;
                </Tooltip> 

    let accept = <Tooltip title="Accetta Preventivo">
                    <Button className="ant-btn-purple" onClick={() => {functions[1](record.code)}} icon={<span role="img" aria-label="close" class="anticon anticon-close"><FontAwesomeIcon style={{height:"15px"}} icon={faSignature} /></span>} size="small"></Button>&nbsp;
                </Tooltip>

    let showOrder = <Tooltip title="Vedi Ordine">
                        <Button className="ant-btn-green" onClick={() => {alert("TODO")/*/*console.log(record)*/}} icon={<FileProtectOutlined />} size="small"></Button>
                    </Tooltip>

    let cancel = <Popconfirm title="Sei sicuro di voler annullare questo preventivo?" okText="Si" cancelText="Chiudi" onConfirm={() => {functions[3](record.code)}}><Tooltip title="Annulla">
                    <Button danger icon={<CloseOutlined />} size="small"></Button>
                </Tooltip></Popconfirm> 

    let manage = <Tooltip title="Gestisci Preventivo">
                    <Button onClick={() => {functions[2](record.code)}} icon={<ToolOutlined />} size="small"></Button>&nbsp;
                </Tooltip>

    let info = <Tooltip title={"Note: " + record.internalNotes}>
                   <InfoCircleOutlined style={{fontSize: "1rem"}} />
                </Tooltip>
    let rv = [];

    if(!EsseLib.IsAdmin()){
        switch(record.status)
        {
            case 10:
            case 20:
                rv.push(show);
                rv.push(cancel);
                break;
            case 22: 
                rv.push(info);
                break;
            case 30:
                rv.push(show);
                rv.push(accept);
                rv.push(cancel);
                break;
            case 40:
                rv.push(showOrder);
                break;
        }
    }
    else
    {
        switch(record.status)
        {
            case 10:
            case 20:
                rv.push(show);
                rv.push(manage);
                rv.push(cancel);
                break;
            case 22: 
                rv.push(info);
                break;
            case 30:
                rv.push(manage);
                rv.push(cancel);
                break;
            case 40:
                rv.push(showOrder);
                break;
        }
    }

    return rv;  
/*
    return (<>
        {record.status % 10 === 0 && 
        <>
        
            {(record.status === 10 || record.status === 20) &&
                
                
            }
            
            {record.status === 30 && 
            <>
                { ?
                 :
                
                }
                
            </>
            }
            {record.status === 40 ? 
                
            :
            }
        </>
        }
    </>)*/
}


export function getQuotationTimeline(status){
    
    let stat = parseInt(String(status)[0])
    let err = parseInt(String(status)[1])

    let description1 = "Il cliente ha inviato i disegni in attesa di preventivo.";
    let description2 = "Il fornitore sta processando un preventivo.";
    let description3 = "L'offerta è disponibile per essere acettata dal cliente.";
    let description4 = "L'offerta è stata accettata e l'ordine è visibile nella pagina \"Ordini\".";

    if(err !== 0)
    {
        switch(stat)
        {
            case 2:
                switch(err)
                {
                    case 1:
                        description2 = "Il cliente ha annullato la richiesta di preventivo";
                        break;
                    case 2:
                        description2 = "Il fornitore ha declinato la richiesta di preventivo";
                        break;
                }
            case 3:
                switch(err)
                {
                    case 1:
                        description3 = "Il cliente ha rifiutato l'offerta"
                        break;
                }
            break;
        }
    }
    
    return <>
    <Steps
        direction="vertical"
        size="small"
        status={err !== 0 ? "error" : "process"}
        current={stat - 1}>
        <Step title="Richiesto" description={description1} />                  
        <Step title="In Lavorazione" description={description2} />
        <Step title="Offerta Disponibile" description={description3}/>
        <Step title="Ordinato" description={description4} />
    </Steps><br/>
   
    </>
}