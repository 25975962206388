import { Button, Tag, Tooltip, Steps } from "antd";
import { CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined, ToolOutlined, FileProtectOutlined, SignatureOutlined } from "@ant-design/icons";
import EsseLib from "../lib/esse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignature } from '@fortawesome/free-solid-svg-icons'

const { Step } = Steps;

export function getStatusTag (status) {
    let rv = <></>
    switch(status)
    {
        case 10:
            rv = <Tag color="blue">Ricevuto</Tag>
            break;
        case 20:
            rv = <Tag color="gold">In Lavorazione</Tag>
            break;
        case 21:
            rv = <Tag color="volcano">Annullato</Tag>
            break;
        case 30:
            rv = <Tag color="purple">Preparazione Alla Spedizione</Tag>
            break;
        case 40:
            rv = <Tag color="lime">Spedito</Tag>
            break;
        case 50:
            rv = <Tag color="green">Consegnato</Tag>
            break;
    }
    return rv;
}

export function getActions (record, functions) {
    return (<>
        {record.status % 10 === 0 && 
        <>
            {(record.status !== 50) &&
                <Tooltip title="Vedi">
                    <Button type="primary" onClick={() => {functions[0](record.code)}} icon={<EyeOutlined />} size="small"></Button>&nbsp;
                </Tooltip> 
            }
            
            {EsseLib.IsAdmin() &&
            <>
                <Tooltip title="Gestisci Preventivo">
                    <Button onClick={() => {functions[1](record.code)}} icon={<ToolOutlined />} size="small"></Button>&nbsp;
                </Tooltip>
                
            </>
            }
        </>
        }
    </>)
}

export const treatmentsOptions = [

    {
      label: "BRUNITURA",
      value: "BURNISHING"
    },
    {
      label: "RAMATURA",
      value: "COPPERING"
    },
    {
      label: "ZINCATURA",
      value: "GALVANIZING"
    },
    {
      label: "STAGNATURA",
      value: "TINNING"
    },
    {
      label: "CROMATURA",
      value: "CHROMIUM"
    },
    {
      label: "LUCIDATURA",
      value: "POLISHING"
    }
    ];

export function getTimeline(status){
    
    let stat = parseInt(String(status)[0])
    let err = parseInt(String(status)[1])

    let description1 = "L'ordine è stato ricevuto correttamente.";
    let description2 = "L'ordine è in lavorazione";
    let description3 = "Stiamo preparando l'ordine per la spedizione";
    let description4 = "L'ordine è stato spedito.";
    let description5 = "L'ordine è stato consegnato.";

    if(err !== 0)
    {
        switch(stat)
        {
            case 2:
                switch(err)
                {
                    case 1:
                        description2 = "L'ordine è stato annullato";
                        break;
                }
        }
    }
    
    return <>
    <Steps
        direction="vertical"
        size="small"
        status={err !== 0 ? "error" : "process"}
        current={stat - 1}>
        <Step title="Ricevuto" description={description1} />                  
        <Step title="In Lavorazione" description={description2} />
        <Step title="Preparazione alla Spedizione" description={description3}/>
        <Step title="Spedito" description={description4} />
        <Step title="Consegnato" description={description4} />
    </Steps><br/>
   
    </>
}