import { Radio, Divider, Row, Form, InputNumber, Input, Select, Checkbox } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from 'react';
import { getQuotationTimeline } from '../helpers/quotationsHelper';
import { treatmentsOptions } from '../helpers/ordersHelper';
import EsseLib from '../lib/esse';

export const ModalManageQuotation = (props) => {
    
    const [state, setState] = useState({
        tabIndex: "1",
        initialValues: {},
        resetFieldsReq: false
    })

    const [resetFields, setResetFields] = useState({});

    useEffect(() => {
        if (state.initialValues?.code !== props?.data?.code) {
            setState({
                ...state, 
                    initialValues: {
                    code: props.data.code,
                    status: state.tabIndex,
                    txtPrice: props.data.offerPrice,
                    txtPriceWithTreatments: props.data.offerPriceWithTreatments
                },
                resetFieldsReq: true
            });
        }
      }, [props.data.code, props.form]);

      useEffect(() => {
        if(state.resetFieldsReq){
            props.form.resetFields();
            setState({...state, resetFieldsReq: false})
        }
      }, [state])

    let onChange = (e) => {
        setState({...state, tabIndex: String(e.target.value) })
        props.form.setFieldsValue({"status": e.target.value});
    }

    let inputNumberChanged = (e) => {

        /*let value = e.target.value.replace("€ ", "");

        props.form.setFieldsValue({[e.target.id]: value});
        console.log(e);*/
    }

    return (<>
        <span>Progetto <b>{props.data.title}</b><br/>&nbsp;</span>
        {getQuotationTimeline(props.data.status)}
        <br/>
        <Radio.Group
            value={state.tabIndex}
            onChange={onChange}
            style={{
                marginBottom: 16,
            }}
        >
            <Radio.Button value={"1"}>Quantifica e Invia</Radio.Button>
            <Radio.Button value={"2"}>Declina Preventivo</Radio.Button>
        </Radio.Group>
        <br/>
        <Divider>{state.tabIndex === "1" ? "Quantifica preventivo" : "Declina Preventivo"}</Divider>
        <Row style={{marginTop: "-.5rem", padding: ".0rem"}}>
            <div style={{marginTop: "2rem", width: "100%"}}>
                <Form
                    form={props.form}
                    /*initialValues={{
                        code: props.data.code,
                        status: "1",
                        txtPrice: props.data.offerPrice,
                        txtPriceWithTreatments: props.data.offerPriceWithTreatments
                    }}*/
                    initialValues={state.initialValues}
                >
                    <div style={{display: "none"}}>
                        <Form.Item name="code" value={props.data.code}>
                            <Input></Input>
                        </Form.Item>
                        <Form.Item name="status" value={state.tabIndex}>
                            <Input></Input>
                        </Form.Item>
                    </div>
                    {state.tabIndex === "1" && 
                        <>
                            
                            <Form.Item
                                name="txtPrice" 
                                label="Prezzo senza trattamenti (€)"
                                //onChange={inputNumberChanged}
                                rules={[
                                    {
                                        required: true,
                                        message: "Obbligatorio"
                                    },
                                    {
                                        min: 1,
                                        type: 'number',
                                        message: "Inserire un valore maggiore di 0"
                                    },
                                ]}>
                                <InputNumber
                                    //formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    min={0}
                                    
                                    //parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    //onChange={onChange}
                                />
                            </Form.Item>
                            
                            {!EsseLib.IsNullOrWhiteSpace(props.data.treatments) && props.data.treatments.indexOf(',') > 0 && <>
                            Trattamenti richiesti:
                            <Select
                                mode="multiple"
                                disabled
                                style={{ width: '100%' }}
                                value={props.data.treatments.split(',')}
                                placeholder="Selezionare tipo di lavorazione"
                                options={treatmentsOptions}
                            />
                                <br/><br/>
                            <Form.Item
                                name="txtPriceWithTreatments" 
                                label="Prezzo con trattamenti (€)"
                                rules={[
                                    {
                                        required: true,
                                        message: "Obbligatorio",
                                    },
                                    {
                                        min: 1,
                                        type: 'number',
                                        message: "Inserire un valore maggiore di 0"
                                    },
                                ]}>
                                <InputNumber
                                    //formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    min={0}
                                    //parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                    //onChange={onChange}
                                />
                            </Form.Item></>}
                        </>
                    }
                    {state.tabIndex === "2" && 
                        <>
                            <Form.Item
                                name="txtNotes" 
                                label="Note"
                                rules={[
                                    {
                                        required: true,
                                        message: "Obbligatorio",
                                    },
                                ]}>
                                <TextArea/>
                            </Form.Item>
                        </>
                    }
                </Form>
            </div> 
        </Row>      

        </>
    );
  };