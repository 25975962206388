import {
  Row,
  Col,
  Card,
  Modal,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";
import { getActions, getStatusTag } from "../helpers/quotationsHelper";
import { useEffect, useState } from "react";
import EsseLib from '../lib/esse';
import ESModal from "../components/esse-components/ESModal";
import { ModalNewQuotation } from "../modals/ModalNewQuotation";
import { ModalViewQuotation } from "../modals/ModalViewQuotation";
import { ModalManageQuotation } from "../modals/ModalManageQuotation";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ModalAcceptQuotation } from "../modals/ModalAcceptQuotation";

const { Title } = Typography;

let data = []


function Quotations() {

  const [loadData, setLoadData] = useState(true)

  const [state, setState] = useState({
    reloadDataReq: false,
    modalViewQuotationOpen: false,
    modalNewQuotationOpen: false,
    modalManageQuotationOpen: false,
    modalAcceptQuotationOpen: false,
    body: [],
    currentViewData: {},
    currentManageData: {},
    currentAcceptData: {},
    filters: {
      statuses: [10, 20, 30]
    }
})

  // eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if(loadData)
		{
      setLoadData(false);
      let params = {
          code: "",
          title: "",
          body: "",
          statuses: state.filters.statuses
      }
      EsseLib.GetRequest("/quotations/", params).then((body) => {
          setState({...state, reloadDataReq: false, body: body})
      });
    }
  }, [loadData]);

  useEffect(() => {
    if(state.reloadDataReq)
    {
      setLoadData(true);
    }
  }, [state])
    
    const columns = [
      { title: 'Codice', dataIndex: 'code', key: 'code'},
      { title: 'Titolo Progetto', dataIndex: 'title', key: 'title'},
      EsseLib.IsAdmin() ?
        { title: 'Utente', dataIndex: 'userInfo', key: 'userInfo', render: (userInfo) => {
          return userInfo === undefined ? "N.D." : (userInfo?.firstName + " " + userInfo?.lastName)
        }} : {}, 
      { title: 'Stato', dataIndex: 'status', key: 'status', render: (status) => {
          return getStatusTag(status)
      }},
      { title: 'Azioni', dataIndex: 'actions', key: 'actions', render: (a, record) => {
          return getActions(record, [onViewClick, onAcceptClick, onManageClick, onCancelClick])
      }},
    ]

    let onNewClick = () => {
      setState({...state, modalNewQuotationOpen: true});
    }

    let getQuotation = (code) => {
      return new Promise((resolve, reject) => {
        let params = {
          code: code
        }
        EsseLib.GetRequest('/quotations/', params).then(body => {
            if(body.length > 0)
            {
                resolve(body);
            }
        }).catch((err) => reject(err));
      });
    }

    let onViewClick = (code) => {
      getQuotation(code).then((body) => {
        setState({...state, modalViewQuotationOpen: true, currentViewData: body[0]});
      });
    }

    let onAcceptClick = (code) => {
      getQuotation(code).then((body) => {
        setState({...state, modalAcceptQuotationOpen: true, currentAcceptData: body[0]});
      });
    }

    let onManageClick = (code) => {
      getQuotation(code).then((body) => {
        setState({...state, modalManageQuotationOpen: true, currentManageData: body[0]});
      });
    }

    let onCancelClick = (code) => {
      updateQuotation(code, 21);
    }
    

    let onModalNewQuotationOk = (e) => {
      console.log(e);
      let params = {
          title: e.txtProjectName,
          body: e.txtProjectDescription,
          treatments: e.cmbProjectTreatments,
          files: e.files
      }
      EsseLib.PostRequest('/quotations', params).then(body => {
        closeAllAndRefresh();
      });
    }

    let closeAllAndRefresh = () => {
      setState({...state, reloadDataReq: true, modalNewQuotationOpen: false, modalManageQuotationOpen: false, modalAcceptQuotationOpen: false});
    }

    let onModalManageQuotationOk = (e) => {
      let params = {}
      if(e.status === "1")
      {
        params = {
          offerPrice: e.txtPrice !== undefined ? e.txtPrice : -1,
          offerPriceWithTreatments: e.txtPriceWithTreatments !== undefined ? e.txtPriceWithTreatments : -1,
          status: 30
        }
      }
      else
      {
        params = {
          status: 22,
          notes: e.txtNotes !== undefined ? e.txtNotes : "",
        }
      }
      //console.log(params)
      EsseLib.PutRequest('/quotations/'+e.code, params).then(body => {
        closeAllAndRefresh();
      });
    }

    let updateQuotation = (code, status, price, notes) => {
      if(code !== undefined && code !== "" && status !== undefined)
      {
        let params = {
          status: status
        };

        if(price !== undefined) { params.price = price }
        if(notes !== undefined) { params.notes = notes }

        EsseLib.PutRequest('/quotations/'+code, params).then(body => {
          closeAllAndRefresh();
        });
      }
    }

    let onModalAcceptQuotationOk = (code, accepted, withTreatments) => {
      if(accepted)
      {
        EsseLib.PutRequest('/quotations/'+code+'/accept', {withTreatments: withTreatments}).then(body => {
          closeAllAndRefresh();
        });
      }
      else
      {
        updateQuotation(code, 31);
      }
    }

  const onChange = (e) => {
    let newFiltersStatuses = state.filters.statuses;
    switch(e.target.value)
    {
      case "0":
        newFiltersStatuses = [10,20,30]
        break;
      case "1":
        newFiltersStatuses = [10,20,21,22,30,31,40]
        break;
    }
    setState({...state, filters: {...state.filters, statuses: newFiltersStatuses}, reloadDataReq: true});
  } 

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Preventivi"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="0">
                    <Radio.Button value="0">Aperti</Radio.Button>
                    <Radio.Button value="1">Tutti</Radio.Button>
                  </Radio.Group>
                  &nbsp;&nbsp;&nbsp;
                  <Button 
                    style={{ float: "right" }}
                    onClick={onNewClick}
					          icon={<PlusCircleOutlined />} type="primary">
                    Richiedi Preventivo
                </Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={state.body}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>

        
      </div>
        <ESModal
            title="Richiedi Preventivo"
            onOk={(e) => onModalNewQuotationOk(e)}
            onCancel={() => setState({...state, modalNewQuotationOpen: false})}
            open={state.modalNewQuotationOpen}>
                <ModalNewQuotation/>
            
        </ESModal>

        <ESModal
            title="Stato Preventivo"
            onlyCancel={true}
            onCancel={() => setState({...state, modalViewQuotationOpen: false})}
            open={state.modalViewQuotationOpen}>
                <ModalViewQuotation data={state.currentViewData}/>
            
        </ESModal>

        <ESModal
            title="Gestisci Preventivo"
            onOk={(e) => onModalManageQuotationOk(e)}
            onCancel={() => setState({...state, modalManageQuotationOpen: false})}
            open={state.modalManageQuotationOpen}>
                <ModalManageQuotation open={state.modalManageQuotationOpen} data={state.currentManageData}/>
            
        </ESModal>

        <ESModal
            title="Rivedi e Accetta Offerta"
            noButtons={true}
            width={600}
            //onOk={(e) => onModalManageQuotationOk(e)}
            onCancel={() => setState({...state, modalAcceptQuotationOpen: false})}
            open={state.modalAcceptQuotationOpen}>
                <ModalAcceptQuotation handleOk={onModalAcceptQuotationOk} data={state.currentAcceptData}/>
            
        </ESModal>

    </>
  );
}

export default Quotations;
