import { Button, Steps, Card, Divider, Row, Form, InputNumber,  Upload } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { PlusCircleOutlined, UploadOutlined, CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined } from "@ant-design/icons";
import EsseLib from '../lib/esse';
import { useState } from 'react';
import { getTimeline } from '../helpers/ordersHelper';
const { Step } = Steps;

export const ModalManageOrder = (props) => {
    
    const [state, setState] = useState({
        tabIndex: "1"
    })
    const uploadProps = {
        onRemove: (file) => {
          const index = props.fileList.indexOf(file);
          const newFileList = props.fileList.slice();
          newFileList.splice(index, 1);
          props.setFileList(newFileList);
        },
        beforeUpload: (file) => {
            props.setFileList([...props.fileList, file]);
          return false;
        },
        fileList: props.fileList,
      };

    let fileDownload = () => {
        EsseLib.DownloadFileRequest("/quotations/files/3", {}).then((data) => {
                
            this.downloadFile(data.file, "myfile.pdf");

        });
    }

    let onChange = (e) => {
        setState({...state, tabIndex: String(e.target.value) })
    }

    return (<>
        <span>Progetto <b>{props.data.title}</b><br/>&nbsp;</span>
        {getTimeline(props.data.status)}
        <Divider>Azioni</Divider>
        <br/>
        Imposta stato Ordine:
        <Row style={{textAlign: "center", marginTop: "-.5rem", padding: ".0rem"}}>
            <div style={{marginTop: "2rem", width: "100%"}}>
                <Button onClick={() => props.handleOk(props.data.code, 20, "")}>In Lavorazione</Button><br/><br/>
                <Button onClick={() => props.handleOk(props.data.code, 30, "")}>In preparazione alla Spedizione</Button><br/><br/>
                <Button onClick={() => props.handleOk(props.data.code, 40, "")}>Spedito</Button><br/><br/>
                <Button onClick={() => props.handleOk(props.data.code, 50, "")}>Consegnato</Button>
            </div> 
        </Row>      

        </>
    );
  };