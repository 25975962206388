import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Quotations from "./pages/Quotations";
import Orders from "./pages/Orders";
import { useEffect } from "react";
import EsseLib from "./lib/esse";
import AppLocale from "./lang";
import { IntlProvider } from "react-intl";

function App() {
  const currentAppLocale = AppLocale['it'];
  EsseLib.setCurrentAppLocale(currentAppLocale);

  return (
    <div className="App">
      <Switch>
        <Route path="/sign-in" exact component={SignIn} />
        <Main>
           <Switch>
            {EsseLib.IsAdmin() &&
            <Route exact path="/dashboard" component={Home} />}
            <Route exact path="/quotations" component={Quotations} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/tables" component={Tables} />
            <Route exact path="/billing" component={Billing} />
            <Route exact path="/profile" component={Profile} />
            <Redirect from="*" to="/dashboard" />
        </Switch>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
