import { env } from './EnvironmentConfig'

export const APP_NAME = 'SamacWMS';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const WSS_BASE_URL = env.WSS_ENDPOINT_URL

export const AUTH_TOKEN  = "AUTH_TOKEN"
export const ROLE_ID  = "ROLE_ID"
export const FIRST_NAME  = "FIRST_NAME"
export const LAST_NAME  = "LAST_NAME"

export const APP_PREFIX_PATH = '/*';
export const SYSTEM_PREFIX_PATH = '/system';
export const CONFIG_PREFIX_PATH = '/config';
export const UTILITIES_PREFIX_PATH = '/utilities';
export const AUTH_PREFIX_PATH = '/auth/*';
export const POOL_PREFIX_PATH = '/pool/*'

export const THEME_CONFIG = {
	navCollapsed: false,
	locale: 'it',
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light'
};
