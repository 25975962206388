import { Button, Steps, Divider, Input, Row, Form, InputNumber,  Upload } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { PlusCircleOutlined, UploadOutlined, CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined } from "@ant-design/icons";
import EsseLib from '../lib/esse';
import { useState } from 'react';
import { getQuotationTimeline } from '../helpers/quotationsHelper';
import moment from 'moment'

const { Step } = Steps;

export const ModalAcceptQuotation = (props) => {
    
    const [state, setState] = useState({
        tabIndex: "1"
    })
    let onChange = (e) => {
        setState({...state, tabIndex: String(e.target.value) })
    }

    return (<>
        {getQuotationTimeline(props.data.status)}
        <span><b>Nome Progetto: </b>{props.data.title}</span><br/>
        <span><b>Descrizione Progetto: </b>{props.data.body}</span>
        <br/>
        <Divider>Offerta del {EsseLib.GetDateTimeIfValid(props.data?.offerDate)}</Divider>
        {EsseLib.IsNullOrWhiteSpace(props.data?.treatments) ? 
        <span><b>Prezzo: </b>{props.data?.offerPrice}$</span> : 
        <>
        <span><b>Prezzo senza trattamenti: </b>{props.data?.offerPrice}$</span><br/>
        <span><b>Prezzo con trattamenti: </b>{props.data?.offerPriceWithTreatments}$</span> 
        </>}
        <br/><br/>
        <div style={{width: "100%", textAlign: "center"}}>
            {EsseLib.IsNullOrWhiteSpace(props.data?.treatments) ? <>
            <Button style={{backgroundColor: "#2ae02a"}} onClick={() => props.handleOk(props.data.code, true, false)}>Accetta</Button>&nbsp;</> : <>
            <Button style={{backgroundColor: "#2ae0d9"}} onClick={() => props.handleOk(props.data.code, true, false)}>Accetta senza Trattamenti</Button>&nbsp;
            <Button style={{backgroundColor: "#2ae02a"}} onClick={() => props.handleOk(props.data.code, true, true)}>Accetta con Trattamenti</Button></>}<br/> <br/>
            <Button style={{backgroundColor: "#e3536f"}} onClick={() => props.handleOk(props.data.code, false)}>Rifiuta</Button>&nbsp;
        </div>

        </>
    );
  };