import { Badge, Steps, Form, Input, Row, Table, Tag, Upload, Select } from 'antd';
import TextArea from "antd/es/input/TextArea";
import { PlusCircleOutlined, UploadOutlined, CheckOutlined, EyeOutlined, CloseOutlined, AuditOutlined } from "@ant-design/icons";
import EsseLib from '../lib/esse';
import { getQuotationTimeline } from '../helpers/quotationsHelper';
import { treatmentsOptions } from '../helpers/ordersHelper';

export const ModalViewQuotation = (props) => {
    
    let fileDownload = (id) => {
        EsseLib.DownloadFileRequest("/quotations/files/"+id, {}).then((data) => {
                
            this.downloadFile(data.file, "myfile.pdf");

        });
    }

    return (<>
        {getQuotationTimeline(props.data.status)}
        <span><b>Nome Progetto: </b>{props.data.title}</span><br/>
        <span><b>Descrizione Progetto: </b>{props.data.body}</span>
        <br/><br/>
        {!EsseLib.IsNullOrWhiteSpace(props.data.treatments) && props.data.treatments.indexOf(',') > 0 && <>
            Trattamenti richiesti:
            <Select
                mode="multiple"
                disabled
                style={{ width: '100%' }}
                value={props.data.treatments.split(',')}
                placeholder="Selezionare tipo di lavorazione"
                options={treatmentsOptions}
            />
        </>}
        <br/><br/>
        <span><b>Files Allegati: </b></span>
        <br/>
        <ul>
        {props.data.files !== undefined && props.data.files.map(f => {
            return  <li><a target='#t' onClick={() => fileDownload(f.id)}>{f.name}</a></li>
        })}
        </ul>
        </>
    );
  };