import { Modal, Form } from 'antd';
import React, { useState } from 'react'


const ESModal = ({title, open, noButtons, onlyCancel, cancelText, onCancel, onOk, children, width}) => {

    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    let newValues = [];

    const additionalProps = {

    };

    function fileToBase64(file, callback) {
        const reader = new FileReader();
      
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          callback(base64String);
        };
      
        reader.readAsDataURL(file);
      }

    let getB64Files = (values, index, callback) => {

        
        if(fileList[index] !== undefined)
        {
            fileToBase64(fileList[index], (base64Data) => {
                
                var filename = fileList[index].name

                newValues.push({name: filename, path: "", content: base64Data});
                getB64Files(values, index + 1, callback);
            });
        }
        else
        {
            callback();
        }
    }
      

    let handleOk = () => {
        form?.validateFields().then(values => {
            newValues = [];
            getB64Files(values, 0, function() {
                values.files = newValues;
                
                onOk(values);
            });
        }).catch(err => {

        });
    }

    // Use the wrapped component and pass additional props
    const wrappedComponentWithProps = React.cloneElement(
        React.Children.only(children),
        { ...additionalProps, form, setFileList, fileList }
    );

    
    let defaultCancelText = "Chiudi";

    return (

        <Modal
            title={title}
            visible={open}
            width={width}
            onOk={handleOk}
            okButtonProps={onlyCancel ? { style: { display: 'none' } } : undefined}
            footer={noButtons === true ? null : undefined}
            cancelText={cancelText !== undefined ? cancelText : (onlyCancel ? defaultCancelText : defaultCancelText)}
            onCancel={onCancel}>
            <div style={{padding: "0 .5rem .5rem .5rem"}}>
                {wrappedComponentWithProps}
            </div>
        </Modal>
    )
}
export default ESModal
